
$ASSETS_PATH:           './assets';

/* FONT */
@import url('https://fonts.googleapis.com/css?family=Poppins:200,400,400i,500,600,700&display=swap');

/* MATERIAL ICONS */
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/* FLAG ICONS */
@import '../../node_modules/flag-icons/sass/flag-icons.scss';

$dark:                  #343a40;
$red:                   #dc3545;
$yellow:                #ffc107;
$green:                 #28a745;
$cyan:                  #17a2b8;

$gray:                  #6c757d;
$gray_400:              #ced4da;
$light_gray:            #dee2e6;
$thin_gray:             #f8f9fa;
