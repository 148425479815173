
/* COLOR BRAND: */
$thin_blue:                     #e8ecff;
$light_blue:                    #647dfc;
$blue:                          #1e2539;
$dark_blue:                     #151c31;
$danger_color:                  #D50037;
$thin_danger_color:             #FFF1F4;

/* 10% DARKEN: */
$thin_blue_darken:              #cfd3e6;
$light_blue_darken:             #4b64e3;
$blue_darken:                   #050c20;
$dark_blue_darken:              #000318;

$palette: (
    "thin_color": $thin_blue,
    "light_color": $light_blue,
    "default_color": $blue,
    "dark_color": $dark_blue,

    "thin_color_darken": $thin_blue_darken,
    "light_color_darken": $light_blue_darken,

    "danger_color":$danger_color,
    "thin_danger_color":$thin_danger_color,
);

$nav_color:                     map-get($palette, "light_color");
$nav_alternative_color:         map-get($palette, "default_color");

$link_color:                    map-get($palette, "light_color");
$form_elements_color:           map-get($palette, "light_color");

$bg_menu_sidebar:               map-get($palette, "default_color");
$bg_switch_menu:                map-get($palette, "thin_color");
$color_switch_menu:             map-get($palette, "default_color");
$bg_menu_active:                map-get($palette, "dark_color");

$bg_sidebar_logo:               map-get($palette, "default_color");
$sidebar_logo:                  "tellya_logo-white.png";
$login_logo:                    "tellya_logo-pantone.png";

$bg_login_image:                "noovleai_galaxy.jpg";

@mixin DefaultButton($bck_color: map-get($palette, "light_color")) {
    background-color: $bck_color;
    color: #fff;
    &:hover {
        background-color: darken($bck_color, 10);
        color: #fff;
    }
}

@mixin LightButton($bck_color: map-get($palette, "thin_color"), $text_color: map-get($palette, "light_color")) {
    background-color: $bck_color;
    color: $text_color;
    &:hover {
        background-color: darken($bck_color, 5);
        color: $text_color;
    }
}

@mixin ThinButton($bck_color: map-get($palette, "thin_color"), $text_color: map-get($palette, "light_color")) {
    background-color: #fff;
    color: $text_color;
    &:hover {
        color: $text_color;
        background-color: #fff;
    }
}
@mixin DangerButton($bck_color: map-get($palette, "thin_danger_color"), $text_color: map-get($palette, "danger_color")) {
    background-color: $bck_color;
    color: $text_color;
    &:hover {
        color: $text_color;
        background-color: $bck_color;
    }
}

@mixin SecondaryButton(){
    padding: 0 0.5rem;
    color: $gray;
    text-transform: uppercase;
    &:hover {
        color: #000;
        background: rgba(transparent, .1);
    }
}

@mixin LightLabel() {
    background-color: map-get($palette, "thin_color");
    color: map-get($palette, "light_color");
    &:hover {
        color: map-get($palette, "light_color");
        border-color: map-get($palette, "light_color");
    }
    &.active {
        background-color: map-get($palette, "light_color");
        color: #fff;
    }
}
